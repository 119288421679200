import React from "react"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import map from "lodash/map"
import CollapsableSection from "./collapsable-section"
import get from "lodash/get"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  images: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  image: {
    maxWidth: theme.spacing(25),
    // minWidth: theme.spacing(20),
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "100%",
    },
  },
  imageWrapper: {
    padding: theme.spacing(1),
    boxSizing: "content-box",
  },
  details: {
    justifyContent: "center",
  },
}))

const Component = ({ cases, displayOptions, title }) => {
  const classes = useStyles()
  const collapsable = get(displayOptions, "collapsable", false)
  const collapsed = get(displayOptions, "collapsed", false)

  const imageList = map(cases, (image, index) => {
    const imagesPerRow = get(displayOptions, "imagesPerRow", 3)
    const style = {
      flexBasis: `${100 / imagesPerRow}%`,
    }

    return (
      <Box
        key={index}
        className={clsx("ImageWrapper", classes.imageWrapper)}
        style={style}
      >
        <Box position="relative" display="flex" className={classes.image}>
          <CardMedia
            component="img"
            src={image.src}
            alt={image.alt}
            className={clsx("Image", image.customClass)}
          />
        </Box>
        <Box className={clsx("title")}>{image.title}</Box>
        <Box
          className={clsx("description")}
          component="div"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {image.description}
        </Box>
      </Box>
    )
  })

  const titleResult = (
    <Typography className="SectionTitle" variant="h5">
      {title}
    </Typography>
  )

  const detailsResult = (
    <Box
      flexDirection="row"
      flexWrap="wrap"
      display="flex"
      className={clsx("ImagesSection", classes.details)}
    >
      {imageList}
    </Box>
  )

  return (
    <Grid container justify="center">
      <Box display="flex" flex={1}>
        {collapsable ? (
          <CollapsableSection
            title={titleResult}
            details={detailsResult}
            collapsed={collapsed}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            borderRadius={15}
            className={clsx("ImageListWrapper", classes.root)}
          >
            {titleResult}
            <Box mt={1} className={clsx("SectionContent", classes.images)}>
              {detailsResult}
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  )
}

Component.propTypes = {
  content: PropTypes.string,
  displayOptions: PropTypes.object,
  cases: PropTypes.array,
  title: PropTypes.string,
}

export default Component
