import React from "react"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import ButtonGroupSection from "components/sections/button-group"
import CustomSection from "components/sections/custom"
import ImagesSection from "components/sections/images"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import clsx from "clsx"

import { shouldHideOnQueryArg } from "utils/index"
import { shouldDisplayToday, getToday } from "utils/date"
import { isLoggedIn } from "services/auth"

const getSectionStyle = (page, section) => {
  const style = {}

  if ((get(page, "theme.sections.textColor", "") || "").length)
    style.color = page.theme.sections.textColor
  if ((get(page, "theme.sections.backgroundColor", "") || "").length)
    style.backgroundColor = page.theme.sections.backgroundColor

  if ((get(section, "displayOptions.textColor", "") || "").length)
    style.color = section.displayOptions.textColor
  if ((get(section, "displayOptions.backgroundColor", "") || "").length)
    style.backgroundColor = section.displayOptions.backgroundColor

  return style
}

const useStyles = makeStyles(theme => ({
  sections: {
    display: "flex",
    padding: "0",
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: "800px",
  },
  wrapper: {
    display: "flex",
  },
  section: {
    marginBottom: theme.spacing(2),
    padding: 0,
    color: theme.palette.text.darkish,
    backgroundColor: theme.palette.background.whiteish,
    borderRadius: "6px",
  },
}))

const ShowSections = ({ sections, location, page }) => {
  const classes = useStyles()
  const matches = useMediaQuery("(max-width:800px)")

  if (isEmpty(sections)) {
    return null
  }

  const today = getToday(location)
  const allSections = sections.map((section, index) => {
    const displayOptions = section.displayOptions || {}
    const displayToday = shouldDisplayToday(today, displayOptions)
    const shouldHideOnQuery = shouldHideOnQueryArg(
      displayOptions.hideOnQueryArg
    )
    if (!displayToday || shouldHideOnQuery) return null

    const authDisplay = get(section, "displayOptions.authDisplay", false)

    if (authDisplay === "connected" && !isLoggedIn()) return null
    if (authDisplay === "not-connected" && isLoggedIn()) return null

    let children

    switch (section.type) {
      case "custom":
        children = <CustomSection {...section} />
        break
      case "images":
        children = <ImagesSection {...section} location={location} />
        break
      case "buttonGroup":
        children = (
          <ButtonGroupSection {...section} location={location} page={page} />
        )
        break
      default:
        children = <CustomSection {...section} location={location} />
    }

    const wrapperStyle = {
      width: matches ? "100%" : get(section, "displayOptions.width", "100%"),
      padding: matches ? "0 20px" : get(section, "displayOptions.padding", 0),
    }

    const sectionStyle = getSectionStyle(page, section)

    return (
      <Container
        key={index}
        style={wrapperStyle}
        className={clsx(
          "SectionWrapper",
          classes.wrapper,
          get(section, "customClass", "")
        )}
      >
        <Box
          display="flex"
          flex="1"
          style={sectionStyle}
          className={clsx(classes.section, `Section--${section.type}`)}
        >
          {children}
        </Box>
      </Container>
    )
  })

  return (
    <Container className={clsx("Sections", classes.sections)}>
      {allSections}
    </Container>
  )
}

ShowSections.propTypes = {
  sections: PropTypes.array.isRequired,
  location: PropTypes.object,
  page: PropTypes.object,
}

export default ShowSections
