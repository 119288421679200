import get from "lodash/get"
import dayjs from "dayjs"
import URI from "urijs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import utc from "dayjs/plugin/utc" // dependent on utc plugin
import timezone from "dayjs/plugin/timezone"
import { isIE } from "utils"

// throw error on IE. Since we do not support it, avoid this error
// and display the "unsupported browser" yellow banner
if (!isIE()) {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(customParseFormat)
  dayjs.tz.setDefault("Europe/Paris")
} else {
  dayjs.tz = function(date) {
    return dayjs(date)
  }
}

export const getTimezoneDate = date => {
  if (!date || !dayjs(date).isValid())
    return dayjs.tz(dayjs().toISOString(), "Europe/Paris")
  return dayjs(date).tz("Europe/Paris", true)
}
export const getToday = location => {
  const { today } = URI(location.search).search(true)

  if (!today || !dayjs(today, "DD-MM-YYYY").isValid()) return getTimezoneDate()

  return getTimezoneDate(dayjs(today, "DD-MM-YYYY"))
}

export const shouldDisplayToday = (today, displayOptions) => {
  if (!get(displayOptions, "displayDatesOptions.activated", false)) {
    return true
  }
  //on invalid date do not display
  // dunno where we could get here since getToday ensure we always have a dayjs valid date :s
  if (!dayjs(today).isValid()) return false

  const start = getTimezoneDate(
    dayjs(get(displayOptions, "displayDatesOptions.start", null), "DD-MM-YYYY")
  )
  const stop = getTimezoneDate(
    dayjs(get(displayOptions, "displayDatesOptions.stop", null), "DD-MM-YYYY")
  )
  const type = get(displayOptions, "displayDatesOptions.type", "show")

  if (!start.isValid() || !stop.isValid()) return false

  if (
    today.isSame(start, "day") ||
    today.isSame(stop, "day") ||
    (today.isAfter(start, "day") && today.isBefore(stop, "day"))
  ) {
    return type === "show"
  }

  return type !== "show"
}
