import React, { Component } from "react"
import PropTypes from "prop-types"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { withStyles } from "@material-ui/core/styles"

import clsx from "clsx"

const styles = () => ({
  root: {
    display: "flex",
    flex: "1 auto",
    flexDirection: "column",
    background: "transparent",
    minHeight: 0,
  },
  expanded: {},
  title: {
    margin: 0,
  },
  button: {
    cursor: "pointer",
  },
})

class CollapsableSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: this.props.collapsed,
    }
  }

  handleChange = () => {
    this.setState(state => ({ collapsed: !state.collapsed }))
  }

  render() {
    const { title, details, classes } = this.props

    return (
      <>
        <Accordion
          className="Accordion"
          classes={{ root: classes.root, expanded: classes.expanded }}
          expanded={!this.state.collapsed}
          onChange={this.handleChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={clsx("AccordionTitle", classes.title)}
          >
            {title}
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {details}
          </AccordionDetails>
        </Accordion>
      </>
    )
  }

  return
}
CollapsableSection.propTypes = {
  title: PropTypes.node,
  details: PropTypes.node,
  classes: PropTypes.object,
  collapsed: PropTypes.bool,
}

CollapsableSection.defaultProps = {
  collapsed: false,
}
export default withStyles(styles)(CollapsableSection)
