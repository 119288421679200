import React from "react"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import get from "lodash/get"
import CollapsableSection from "./collapsable-section"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.white,
  },
  body: {
    "& img": {
      maxWidth: "100%",
    },
  },
}))

const Component = ({ content, displayOptions, title }) => {
  const classes = useStyles()
  const collapsable = get(displayOptions, "collapsable", false)
  const collapsed = get(displayOptions, "collapsed", false)

  const titleResult = (
    <Typography className="SectionTitle" variant="h5">
      {title}
    </Typography>
  )

  const detailsResult = (
    <Box
      className={clsx("CustomSectionContent", classes.body)}
      dangerouslySetInnerHTML={{ __html: content }}
    ></Box>
  )

  return (
    <Grid container justify="flex-start">
      <>
        {collapsable ? (
          <CollapsableSection
            title={titleResult}
            details={detailsResult}
            collapsed={collapsed}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            className={clsx("CustomSectionRoot", classes.root)}
          >
            {titleResult}
            <Box mt={1} className="SectionContent">
              {detailsResult}
            </Box>
          </Box>
        )}
      </>
    </Grid>
  )
}

Component.propTypes = {
  content: PropTypes.string,
  displayOptions: PropTypes.object,
  title: PropTypes.string,
  collapsable: PropTypes.bool,
  collapsed: PropTypes.bool,
}

export default Component
