import React, { Component } from "react"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"

import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"

import clsx from "clsx"
import URI from "urijs"
import { generateHash } from "utils/generateHash"
import { fetchApiData } from "utils/fetchApiData"
import get from "lodash/get"
import { getUser, isLoggedIn, getUserSession } from "services/auth"
import emitter, { events } from "utils/emitter"
import { _t } from "utils/i18n"
import { userEventsMap } from "services/auth"

const styles = theme => ({
  root: {
    flexAlign: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  confirm: {
    backgroundColor: theme.palette.background.default,
    overflow: "auto",
    borderRadius: "6px",
    padding: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    outline: "none !important",
    "&focus": {
      outline: "none !important",
    },
  },
  confirmModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1501 !important",
  },
})

class RegisterButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      confirm: false,
      registeredToEvent: false,
      registering: false,
      registrationEvent: null,
      participantMaxError: null,
      registrationDisabled: false,
    }
  }

  mounted = false

  componentDidMount() {
    this.mounted = true
    emitter.on(events.userEventsUpdated, () => this.forceUpdate())
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleRegisterToEvent = async () => {
    if (this.mounted) {
      this.setState({ registering: true, confirm: false })
    }

    //const { session, event } = this.props;
    const baseTarget = new URI(`./`)
      .segment(`event`)
      .segment(this.props.wiz)
      .segment(`register`)

    // pop modal signin.
    // TODO: find a smart way to check this button again on login/singin completion..
    if (!isLoggedIn()) {
      this.setState({ registering: false })
      emitter.emit(events.showLogin, false)
      return false
    }

    const session = getUserSession()
    const user = getUser()

    this.setState({ loading: true })

    try {
      let resourceData = await fetchApiData(baseTarget.normalize(), {
        session: { token: session.token },
        options: {
          method: `POST`,
          body: {
            hash: generateHash(),
            lang: this.props.lang,
            email: user.email,
          },
        },
      })

      if (this.mounted) {
        this.setState({
          registeredToEvent: true,
          loading: false,
          registering: false,
          registrationEvent: resourceData,
        })
        emitter.emit(
          events.userRegistered,
          /* prettier-ignore */
          _t("Your registration has been successfully completed, we have also just sent you an email.")
        )
      }

      return resourceData
    } catch (error) {
      if (this.mounted) {
        const errorCode = get(error, `extra.code`, `code missing`)
        this.setState({
          loading: false,
          registering: false,
          participantMaxError:
            errorCode === `number_registered_participants_exceeded`,
          registrationDisabled:
            errorCode === "landing_registration_disabled" ||
            errorCode === "landing_disabled" ||
            errorCode === "landing_not_found",
        })
      }
    }

    return null
  }

  render() {
    const { wiz, title, classes, customClass, style } = this.props

    let errorMessage
    let successMessage

    const {
      registrationDisabled,
      participantMaxError,
      registering,
      loading,
      confirm,
    } = this.state

    const registeredToEvent =
      this.state.registeredToEvent || !!userEventsMap[wiz.toLowerCase()]

    if (registrationDisabled) {
      errorMessage = _t("The registration is not enabled for this Wiz!")
    } else if (participantMaxError) {
      errorMessage = _t("This Wiz reached its maximum invitations capacity!")
    }

    if (registeredToEvent) {
      successMessage = _t(" - registered !")
    }

    return (
      <>
        {confirm && !registering && (
          <Modal
            open={true}
            className={classes.confirmModal}
            onClose={() => {}}
            BackdropComponent={Backdrop}
          >
            <Box
              display="flex"
              flexDirection="column"
              className={classes.confirm}
            >
              <Box display="flex" flex="1 auto">
                {/* prettier-ignore */}
                <Typography>
                  {_t("You're about to register to this session, are you sure?")}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" mt={4}>
                <Box mr={1}>
                  <Button
                    onClick={() => this.setState({ confirm: false })}
                    variant="contained"
                    color="primary"
                  >
                    {_t("Cancel")}
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    onClick={this.handleRegisterToEvent}
                    variant="contained"
                    color="secondary"
                  >
                    {_t("Register")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}

        <Button
          onClick={() => this.setState({ confirm: true })}
          disabled={registeredToEvent || registering || registrationDisabled}
          display="flex"
          className={clsx(classes.button, customClass)}
          style={style}
        >
          <Typography component="div" style={style} color="inherit">
            <span>{errorMessage || title}</span>
            <span>{loading && <CircularProgress size={15} />}</span>
            <span>{successMessage}</span>
          </Typography>
        </Button>
      </>
    )
  }
}

RegisterButton.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object,
  customClass: PropTypes.string,
  wiz: PropTypes.string,
  style: PropTypes.object,
  lang: PropTypes.string,
}
RegisterButton.defaultProps = {
  lang: PropTypes.string,
}

export default withStyles(styles)(RegisterButton)
