import React from "react"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import get from "lodash/get"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import map from "lodash/map"
import set from "lodash/set"

import { getToday, shouldDisplayToday } from "utils/date"
import { shouldHideOnQueryArg } from "utils/index"

import CollapsableSection from "./collapsable-section"
import CustomButton from "./custom-button"

import WithRegistrationToWiz from "components/hoc/with-registration"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: props =>
      props.backgroundColor ? props.backgroundColor : "transparent",
    marginTop: props =>
      props.marginTop ? theme.spacing(props.marginTop) : theme.spacing(0),
    marginBottom: props =>
      props.marginBottom ? theme.spacing(props.marginBottom) : theme.spacing(0),
  },
  button: {
    cursor: "pointer",
  },
}))

const ButtonGroup = ({
  displayOptions,
  buttons,
  buttonsRaw,
  location,
  title,
  page,
}) => {
  const today = getToday(location)

  const collapsable = get(displayOptions, "collapsable", false)
  const collapsed = get(displayOptions, "collapsed", false)
  const flexDirection = get(displayOptions, "flexDirection", "row")
  const classes = useStyles({
    marginTop: get(displayOptions, "marginTop", 0),
    marginBottom: get(displayOptions, "marginBottom", 0),
    backgroundColor: get(displayOptions, "backgroundColor", null),
  })

  let buttonsJsonList = []
  const buttonsJsonData =
    buttonsRaw && buttonsRaw.json ? JSON.parse(buttonsRaw.json) : []
  buttonsJsonData.forEach((data, i) => {
    let button = {}
    Object.keys(data).forEach(index => {
      set(button, index, data[index])
    })
    buttonsJsonList[i] = button
  })

  const buttonList = buttons => {
    return map(buttons, (button, index) => {
      if (
        shouldHideOnQueryArg(
          get(button, "displayOptions.hideOnQueryArg", false)
        )
      )
        return null

      if (!shouldDisplayToday(today, button.displayOptions)) return null

      const buttonEl = (
        <Box
          className={clsx("ButtonWrapper", button.customClass)}
          display="flex"
          flex={1}
          mx={1}
          flexDirection="column"
          key={index}
        >
          <CustomButton {...button} button={button} today={today} page={page} />
        </Box>
      )

      if (
        button.type === "wiz" &&
        get(button, "displayOptions.onlyParticipant", false)
      )
        return (
          <WithRegistrationToWiz
            wiz={button.wiz?.toLowerCase()}
            key={`withRegistration${index}`}
          >
            {buttonEl}
          </WithRegistrationToWiz>
        )

      return buttonEl
    })
  }

  const titleResult = (
    <Typography className="SectionTitle" variant="h5">
      {title}
    </Typography>
  )

  const detailsResult = (
    <Box
      display="flex"
      flex={1}
      flexWrap={flexDirection === "row" ? "wrap" : "unset"}
      flexDirection={flexDirection ? flexDirection : "row"}
      className={clsx("ButtonGroupContentSection")}
    >
      {buttonList(buttons)}
      {buttonList(buttonsJsonList)}
    </Box>
  )

  return (
    <Grid container justify="flex-start">
      <>
        {collapsable ? (
          <CollapsableSection
            title={titleResult}
            details={detailsResult}
            collapsed={collapsed}
          />
        ) : (
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            className={clsx("ButtonGroupSection", classes.root)}
          >
            {titleResult}
            <Box mt={1} className="SectionContent SectionContent-Buttons">
              {detailsResult}
            </Box>
          </Box>
        )}
      </>
    </Grid>
  )
}

ButtonGroup.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
  buttonsRaw: PropTypes.object,
  displayOptions: PropTypes.object,
  location: PropTypes.object,
  page: PropTypes.object,
}

ButtonGroup.defaultProps = {
  wrapButton: false,
  flexDirection: "column",
}

export default ButtonGroup
