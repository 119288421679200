import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"

import { _t } from "utils/i18n"
import { getCookie, setCookie } from "utils/basil"

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: 50,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    zIndex: theme.zIndex.tooltip - 1,
    margin: "0 15px",
    padding: 16,
    background: "#fff",
    borderRadius: 8,
    boxShadow: "0 3px 3px rgba($black, .25)",
    [theme.breakpoints.up("xs")]: {
      bottom: 15,
      maxWidth: 496,
    },
  },
  notice: {
    paddingRight: theme.spacing(1),
    fontFamily: "Inter, -apple-system, BlinkMacSystemFont, sans-serif",
  },
  button: {
    padding: theme.spacing(1),
    color: "#fff",
    background: "#46b489",
    cursor: "pointer",
    borderRadius: 6,
    minWidth: 34,
    height: 34,
    "&:hover": {
      background: "#41a07a",
    },
  },
}))

// use exact same code and syntax with wisembly-front to use that thing between the subdomains
const getStoredConsent = () => getCookie({key:"cookies.consent" })

const setStoredConsent = value => {
  setCookie({key: "cookies.consent", value, conf:{expireDays: 30 * 13}})

}

const CookiesConsent = () => {
  const classes = useStyles()

  const hide = () => {
    setStoredConsent(true)
    document.querySelector(".CookiesConsentWrapper").remove()
  }

  if (getStoredConsent() !== null) return null

  const url = _t("https://wisembly.zendesk.com/hc/en-us/articles/360017674700")

  return (
    <Box className={clsx("CookiesConsentWrapper", classes.root)}>
      <Box display="flex" className="CookiesConsent">
        <Box className={clsx("CookiesNotice", classes.notice)}>
          {_t("By browsing this website, you accept the use of cookies 🍪, as they help us optimise your experience.")}
          &nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href={url}
          >
            {_t("Learn more")}
          </a>
        </Box>
        <Button
          onClick={hide}
          className={clsx("CookiesConsentValidate", classes.button)}
        >
          Ok
        </Button>
      </Box>
    </Box>
  )
}

export default CookiesConsent
