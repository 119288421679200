import React, { Component } from "react"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import get from "lodash/get"
import URI from "urijs"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import DoneIcon from "@material-ui/icons/Done"

import clsx from "clsx"
import RegisterButtonBase from "./register-button"
import emitter, { events, mountEvents, unmountEvents } from "utils/emitter"

const getButtonStyle = (page, button) => {
  const style = {}

  if ((get(page, "theme.buttons.textColor", "") || "").length)
    style.color = page.theme.buttons.textColor
  if ((get(page, "theme.buttons.backgroundColor", "") || "").length)
    style.backgroundColor = page.theme.buttons.backgroundColor

  if ((get(button, "displayOptions.textColor", "") || "").length)
    style.color = button.displayOptions.textColor
  if ((get(button, "displayOptions.backgroundColor", "") || "").length)
    style.backgroundColor = button.displayOptions.backgroundColor

  return style
}

const getButtonWrapperStyle = (page, button) => {
  const style = {}

  if ((get(page, "theme.buttons.wrapper.textColor", "") || "").length)
    style.color = page.theme.buttons.wrapper.textColor
  if ((get(page, "theme.buttons.wrapper.backgroundColor", "") || "").length)
    style.background = page.theme.buttons.wrapper.backgroundColor

  if ((get(button, "displayOptions.wrapper.textColor", "") || "").length)
    style.color = button.displayOptions.wrapper.textColor
  if ((get(button, "displayOptions.wrapper.backgroundColor", "") || "").length)
    style.background = button.displayOptions.wrapper.backgroundColor

  return style
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: props =>
      props.marginTop ? theme.spacing(props.marginTop) : theme.spacing(0),
    marginBottom: props =>
      props.marginBottom ? theme.spacing(props.marginBottom) : theme.spacing(2),
  },
  button: {
    cursor: "pointer",
  },
  wrapper: {
    padding: theme.spacing(2),
    marginBottom: props =>
      props.marginBottom ? theme.spacing(props.marginBottom) : theme.spacing(2),
  },
}))

const EVENTS = {
  [events.hasAnsweredPreliminaryForm]: "onHasAnswered",
}

class InnerButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasAnsweredAt: false,
    }
  }

  componentDidMount() {
    if (this.props.type !== "form") return
    mountEvents(EVENTS, this)
  }

  componentWillUnmount() {
    if (this.props.type !== "form") return
    unmountEvents(EVENTS, this)
  }

  onHasAnswered(hasAnsweredAt) {
    this.setState({ hasAnsweredAt })
  }

  render() {
    const { type } = this.props
    const { hasAnsweredAt } = this.state

    let openIn = null

    switch (type) {
      case "external":
        openIn = "_blank"
        break
      case "samepage":
      default:
        openIn = "_parent"
        break
    }

    return (
      <Button
        display="flex"
        href={this.props.href}
        target={openIn}
        className={this.props.className}
        style={this.props.style}
        disabled={type === "form" && hasAnsweredAt !== false}
        onClick={event => {
          if (type !== "form") return

          event.preventDefault()
          emitter.emit(events.showPreliminaryForm, true)
        }}
      >
        <Typography color="inherit">
          {this.props.title}&nbsp;{hasAnsweredAt ? <DoneIcon /> : ""}
        </Typography>
      </Button>
    )
  }
}

InnerButton.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
}

const RegisterButton = ({ page, title, wiz, displayOptions, button }) => {
  const classes = useStyles({
    marginTop: get(displayOptions, "marginTop", 0),
    marginBottom: get(displayOptions, "marginBottom", 0),
  })

  return (
    <Box
      className={clsx(classes.root, "Button")}
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
    >
      <RegisterButtonBase
        lang={page.lang}
        title={title}
        wiz={wiz}
        style={getButtonStyle(page, button)}
      />
    </Box>
  )
}

RegisterButton.propTypes = {
  displayOptions: PropTypes.object,
  title: PropTypes.string,
  page: PropTypes.object,
  wiz: PropTypes.object,
  button: PropTypes.object,
}

const CustomButton = ({
  title,
  registerButton,
  link,
  type,
  wiz,
  displayOptions,
  page,
  button,
}) => {
  displayOptions = displayOptions || {}

  const classes = useStyles({
    marginTop: get(displayOptions, "marginTop", 0),
    marginBottom: get(displayOptions, "marginBottom", 0),
  })

  const style = getButtonStyle(page, button)

  const href =
    type !== "wiz"
      ? link
      : URI(process.env.APP_BASE)
          .segment(wiz)
          .fragment(`stream`)
          .toString()

  if (
    registerButton &&
    type === "wiz" &&
    !get(displayOptions, "wrapper.activated", false)
  )
    return (
      <RegisterButton
        displayOptions={displayOptions}
        page={page}
        wiz={wiz}
        title={title}
        button={button}
      />
    )

  if (get(displayOptions, "wrapper.activated", false)) {
    const wrapperStyle = getButtonWrapperStyle(page, button)

    return (
      <Box
        display="flex"
        flexDirection="column"
        className={clsx(classes.wrapper, displayOptions.wrapper.customClass)}
        style={wrapperStyle}
      >
        <Box
          className={clsx("Title")}
          dangerouslySetInnerHTML={{
            __html: displayOptions.wrapper.title,
          }}
        />
        <Box
          className={clsx("Description")}
          dangerouslySetInnerHTML={{
            __html: displayOptions.wrapper.description,
          }}
        />
        <Box
          className="Button"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          {!get(displayOptions, "wrapper.activated", false) ? (
            <InnerButton
              title={title}
              href={href}
              type={type}
              className={clsx(classes.button, "Button")}
              style={style}
            />
          ) : (
            <RegisterButton
              displayOptions={displayOptions}
              page={page}
              wiz={wiz}
              title={title}
              button={button}
            />
          )}
        </Box>
      </Box>
    )
  }

  return (
    <InnerButton
      title={title}
      href={href}
      type={type}
      className={clsx(classes.button, classes.root, "Button")}
      style={style}
    />
  )
}

CustomButton.defaultProps = {
  type: "wiz",
}

CustomButton.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  wiz: PropTypes.string,
  link: PropTypes.string,
  displayOptions: PropTypes.object,
  registerButton: PropTypes.bool,
  page: PropTypes.object,
  button: PropTypes.object,
}

export default CustomButton
