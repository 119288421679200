import React, { Component } from "react"
import PropTypes from "prop-types"
import { userEventsMap } from "services/auth"
import emitter, { events } from "utils/emitter"

//export function withWiz(WrappedComponent) {
export default class WithRegistrationToWiz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      registeredToEvent: false,
      loading: false,
    }
  }

  componentDidMount() {
    emitter.on(events.userEventsUpdated, () => this.forceUpdate())
  }

  render() {
    if (!userEventsMap[this.props.wiz]) {
      return null
    }

    return <>{this.props.children}</>
  }
}

WithRegistrationToWiz.propTypes = {
  children: PropTypes.node,
  wiz: PropTypes.string,
}
