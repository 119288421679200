import React, { Component } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import PropTypes from "prop-types"
import Layout from "components/layout"
import ShowSections from "components/show-sections"
import CookiesConsent from "components/cookies-consent"

import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import { withStyles } from "@material-ui/core/styles"

import emitter, { events } from "utils/emitter"
import {
  isLoggedIn,
  getEvents,
  clearEvents,
  checkLogin,
  getUser,
} from "services/auth"

import { setWindow } from "utils/window"

const styles = theme => ({
  loader: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: theme.zIndex.tooltip + 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#bac1c3",
    background: "linear-gradient(45deg, #bac1c3, #ebeeef 60%)",
  },
})

const fetchData = async () => {
  await getEvents()
}

class Homepage extends Component {
  constructor(args) {
    super(args)

    this.state = {
      checkLogin: false,
    }
  }

  componentDidMount() {
    emitter.on(events.userConnected, () => this.forceUpdate())
    emitter.on(events.userLogout, () => this.forceUpdate())

    emitter.on(events.userConnected, fetchData)
    emitter.on(events.userLogout, clearEvents)

    checkLogin().then(response => {
      this.setState({ checkLogin: true })

      if (response && isLoggedIn()) return
      emitter.emit(events.userLogout)
    })

    // expose page data to window for custom js scripts
    setWindow(`page`, get(this.props.data, "markdownRemark.frontmatter", {}))
  }

  render() {
    const { classes, data } = this.props
    const page = get(data, "markdownRemark.frontmatter", {})
    const user = getUser()

    if (!this.state.checkLogin) {
      return (
        <Box className={classes.loader}>
          <CircularProgress color="secondary" />
        </Box>
      )
    }

    return (
      <Layout user={user} page={page} location={location}>
        <ShowSections
          sections={page.sections}
          location={location}
          page={page}
        />
        <CookiesConsent />
      </Layout>
    )
  }
}

Homepage.propTypes = {
  data: PropTypes.object,
  path: PropTypes.string,
  location: PropTypes.object,
  classes: PropTypes.object,
}

export const pageQuery = graphql`
  query($keyword: String!) {
    markdownRemark(frontmatter: { keyword: { eq: $keyword } }) {
      frontmatter {
        title
        template
        lang
        keyword
        authenticationRequired
        forceVerify
        wizMember
        title
        loader {
          activated
          durationSeconds
          shouldWaitForUserData
        }
        preliminaryForm {
          thankYouMessage
          activated
          url
          pop
          submitButtonText
        }
        login {
          title
          description
          defaultScreen
          cgu {
            activated
            content
            mandatory
          }
        }
        mail {
          welcome
        }
        sections {
          template
          type
          title
          content
          customClass
          buttonsRaw {
            json
          }
          buttons {
            customClass
            registerButton
            template
            title
            type
            wiz
            link
            displayOptions {
              textColor
              backgroundColor
              marginTop
              marginBottom
              hideOnQueryArg
              onlyParticipant
              displayDatesOptions {
                activated
                start
                stop
                type
              }
              wrapper {
                activated
                title
                description
                backgroundColor
                textColor
                customClass
              }
            }
          }
          cases {
            template
            title
            description
            src
            alt
            customClass
          }
          displayOptions {
            width
            padding
            collapsable
            collapsed
            flexDirection
            marginTop
            marginBottom
            textColor
            backgroundColor
            hideOnQueryArg
            imagesPerRow
            authDisplay
            displayDatesOptions {
              activated
              start
              stop
              type
            }
          }
        }
        theme {
          fontColor
          css {
            activated
            inline
            file
          }
          js {
            activated
            file
          }
          background {
            img
            applyBackgroundColor
            color
          }
          header {
            activated
            fontColor
          }
          topBar {
            activated
          }
          logo {
            img
          }
          sections {
            backgroundColor
            textColor
          }
          buttons {
            backgroundColor
            textColor
            wrapper {
              backgroundColor
              textColor
            }
          }
        }
      }
    }
  }
`

export default withStyles(styles)(Homepage)
