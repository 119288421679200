export function generateHash() {
  let res = ``

  let pool = `0123456789abcdef`
  let size = 40

  for (let t = 0; t < size; ++t)
    res += pool.charAt(Math.floor(Math.random() * pool.length))

  return res
}
